import logo from "./logo.svg";
import "./fonts/font.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import bg from "./resources/background.png";
import gavel from "./resources/gavel2.png";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useState } from "react";

function App() {
  let [sessionID, setSessionID] = useState("");

  let down = true;

  return (
    <Container
      style={{
        height: "100vh",
      }}
    >
      <Row
        className="App"
        style={{
          flexDirection: "column",
        }}
      >
        <Col className="App-header">
          <h1
            style={{
              fontFamily: "Poppins-Bold",
              color: "white",
              fontSize: "4rem",
              background: "transparent",
            }}
          >
            Mock Auction Software - IPL like
          </h1>
        </Col>
        {!down ? (
          <Col
            className="App-header"
            style={{
              marginTop: "28vh",
            }}
          >
            <h1
              style={{
                fontFamily: "Poppins-Bold",
                color: "red",
                fontSize: "4rem",
                background: "transparent",
              }}
            >
              Update - <br />
              <span
                style={{
                  fontSize: "3rem",
                }}
              >
                {" "}
                Software is down for maintenance
              </span>
            </h1>
          </Col>
        ) : (
          <>
            <Col className="free-btn-col">
              <a
                href="https://auctionadmin.spectors.in/signup"
                className="free-btn"
                link=""
              >
                <span>GET FOR FREE!</span>
                <img src={gavel} alt="gavel" />
              </a>
            </Col>
            <Col
              style={{
                marginTop: "4rem",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <h1
                style={{
                  fontFamily: "Poppins-Bold",
                  color: "white",
                  fontSize: "3rem",
                  marginBottom: "1rem",
                  background: "transparent",
                }}
              >
                How to Play
              </h1>{" "}
              <iframe
                width="711.1"
                height="400"
                src="https://www.youtube.com/embed/nOeJQv-H5zI"
                title="How to use SPECAUC Mock auction software -  Full Tutorial"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                referrerpolicy="strict-origin-when-cross-origin"
                allowfullscreen
              ></iframe>
            </Col>
            <Col className="links">
              <Col className="link">
                <div style={{ fontSize: "3rem" }}>
                  Live Screen
                  {/* <a href="https://auction.spectors.in/bidder">
                  auction.spectors.in/live
                </a> */}
                </div>
                <span style={{ fontFamily: "Poppins-Regular" }}>
                  Enter Session ID
                  {/* <a href="https://auction.spectors.in/bidder">
                  auction.spectors.in/live
                </a> */}
                </span>
                <Form.Control
                  value={sessionID}
                  onChange={(e) => {
                    setSessionID(e.target.value);
                  }}
                  style={{ margin: "1rem auto", width: "10rem" }}
                />
                <span>
                  <a
                    href="https://auction.spectors.in/admin"
                    className="go-btn"
                    link=""
                    onClick={(e) => {
                      e.preventDefault();
                      if (sessionID && sessionID.length > 10) {
                        window.location.href =
                          "https://auction.spectors.in/live/" + sessionID;
                      } else {
                        alert("Wrong Session ID");
                      }
                    }}
                  >
                    <span>GO!</span>
                    <img src={gavel} alt="gavel" />
                  </a>
                </span>
              </Col>
              <Row style={{ flexDirection: "column", color: "white" }}>
                <Col className="link" style={{ marginTop: "2rem" }}>
                  Admin Portal -{" "}
                  <span>
                    <a href="https://auction.spectors.in/admin">
                      auction.spectors.in/admin
                    </a>
                  </span>
                </Col>

                <Col className="link">
                  Curator Portal -{" "}
                  <span>
                    <a href="https://auction.spectors.in/curator">
                      auction.spectors.in/curator
                    </a>
                  </span>
                </Col>
                <Col className="link">
                  Bidder Portal -{" "}
                  <span>
                    <a href="https://auction.spectors.in/bidder">
                      auction.spectors.in/bidder
                    </a>
                  </span>
                </Col>

                <Col className="contact-us">
                  for any queries, contact at{" "}
                  <a href="mailto: specauc@email.com">specauc@gmail.com</a>
                </Col>
              </Row>
            </Col>
          </>
        )}
      </Row>
    </Container>
  );
}

export default App;
